import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useEffect } from "react";
import emailjs from "@emailjs/browser";

import Stoneway from "spa/stoneway.js";

export default function App() {
  useEffect(() => {
    emailjs.init({
      publicKey: "obyopRh6eyQJc4NPl",
      blockHeadless: true,
    });
  }, []);
  return (
    <>
      <GlobalStyles />
      <Stoneway />
    </>
  );
}
