import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import warranty from "images/warranty.png";
import home from "images/home.png";

import house from "images/landscape/stairs.webp";
import working from "images/landscape/working.webp";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={
          <>
            We Build <HighlightedText>To Last</HighlightedText>
          </>
        }
        description="Stoneway Landscaping & Renovation is dedicated to providing the highest quality work for both residential and commercial clients in the Ottawa area."
        imageSrc={house}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="View Projects"
        primaryButtonUrl="#projects"
      />
      <div id="about">
        <MainFeature
          subheading={<Subheading>Established Since 2014</Subheading>}
          heading={<>Select from a wide range of specialties.</>}
          description={
            <Description>
              Contact us today and let us help you plan, design, complete your
              next dream project. For best customer satisfaction, we offer
              <HighlightedText>3-year warranty</HighlightedText>
              <br />
              <br />
              The services we provide include interlock driveways, walkways,
              steps, patios, flowerbeds, retaining walls, landscaping lighting,
              and much more.{" "}
            </Description>
          }
          buttonRounded={false}
          textOnLeft={false}
          primaryButtonText="View Specialties"
          primaryButtonUrl="#projects"
          imageSrc={working}
          imageCss={imageCss}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        />
      </div>
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <div id="projects">
        <TabGrid
          heading={
            <>
              Our <HighlightedText>Specialties</HighlightedText>
            </>
          }
        />
      </div>
      <Features
        heading={
          <>
            <HighlightedText>Dream Landscapes</HighlightedText> In Progress
          </>
        }
        description="Stoneway Landscaping & Renovation turns your landscaping dreams into a reality. Transforming your outdoor into the ideal space."
        cards={[
          {
            imageSrc: warranty,
            title: "3-year warranty",
            description:
              "Free warranty for 3 years on all projects. We are confident in our work.",
          },
          {
            imageSrc: home,
            title: "Increased Home Value",
            description:
              "Landscaping is one of the best ways to increase your property value.",
          },
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <div id="contact">
        <MainFeature2
          subheading={
            <Subheading>Your Home Is Important, Give It A Makeover</Subheading>
          }
          heading={<>Request a free quote</>}
          statistics={[]}
          // statistics={[
          //   {
          //     key: "Project Quality",
          //     value: "5/5",
          //   },
          //   {
          //     key: "Customer Service",
          //     value: "4.5/5",
          //   },
          //   {
          //     key: "Overall Satisfaction",
          //     value: "5/5",
          //   },
          // ]}
          primaryButtonText="Submit"
          description={
            <>
              Get a rough idea of pricing for the various services we offer. If
              the service type is not listed, feel free to select the Other
              option and specify in the message area.
              <br />
              <br /> We respond within 24 hours! You can also contact us
              directly through email and give us a call at the number below.
              <br />
              <br />
              <HighlightedText>Contact Us Directly</HighlightedText>
              <br />
              <br />
              <HighlightedTextInverse>Phone:</HighlightedTextInverse>
              (613) 890-1000
              <br />
              <HighlightedTextInverse>Email:</HighlightedTextInverse>
              info@stonewaylandscape.ca
            </>
          }
          textOnLeft={true}
        />
      </div>
      {/* <Testimonial
        subheading=""
        heading={
          <>
            Customers <HighlightedText>Love Us.</HighlightedText>
          </>
        }
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
