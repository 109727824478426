import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import LogoImage from "images/stoneway-landscaping-desc-other-green.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "images/black-instagram-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-around -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-20`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>Address</ColumnHeading>
            <>
              Stoneway Landscaping & Renovations
              <br />
              <br />
              1345 Marchand Street
              <br />
              Ottawa ON, Canada
            </>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <>
              613-890-1000
              <br />
              <br />
              Do you have any question? Feel free to reach out.
              <br />
              <a href="mailto:info@stonewaylandscape.ca">
                info@stonewaylandscape.ca
              </a>
            </>
          </Column>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg
              alt="Stoneway Landscape & Renovation Logo."
              src={LogoImage}
            />
          </LogoContainer>
          <CopywrightNotice>
            &copy; 2024 Stoneway Landscape & Renovations Inc. All Rights
            Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink
              aria-label="Link to stoneway landscape facebook page."
              href="https://www.facebook.com/stonewayreno/"
            >
              <FacebookIcon />
            </SocialLink>
            <SocialLink
              aria-label="Link to stoneway landscape instagram page."
              href="https://www.instagram.com/stonewaylandscape.ca/"
            >
              <InstagramIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
